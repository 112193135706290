.playing-bars-animations {
    display: flex;
    height: 10px;
    max-height: 10px;
    margin: auto;
    width: 24px;
    &.big {
        height: 100px;
        max-height: 100px;
        width: 75px;
        opacity: 0.7;
    }
    div {
        border-radius: 15px 15px 0 0;
        background: rgb(8, 23, 83);
        width: 3px;
        height: 10px;
        transform-origin: bottom;
        animation-name: PlayingBarsAnimation;
        animation-iteration-count: infinite;
        animation-play-state: paused;
    }
    &.big div {
        width: 15px;
        height: 100px;
        border-radius: 15px;
        background: rgb(73, 78, 99);
        transform-origin: center;
    }
    &.playing div {
        animation-play-state: running;
    }
    div + div {
        margin-left: 2px;
    }
    &.big div + div {
        margin-left: 5px;
    }
    div:first-of-type {
        animation-duration: 0.95s;
        animation-delay: 0.1s;
    }
    div:nth-of-type(2) {
        animation-duration: 0.85s;
    }
    div:nth-of-type(3) {
        animation-duration: 0.75s;
        animation-delay: 0.3s;
    }
    div:nth-of-type(4) {
        animation-duration: 0.9s;
        animation-delay: 0.1s;
    }
}

@keyframes PlayingBarsAnimation {
    from {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(0.4);
    }
    to {
        transform: scaleY(1);
    }
}

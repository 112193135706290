body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f5f6f7;
    // overflow: hidden;

    &.fullscreen.mobile {
        background-color: black;
    }
}
* {
    box-sizing: border-box;
}

body.modalOpen #root {
    transition: 0.2s ease-in-out all;
    filter: blur(1.5px);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.clickable {
    cursor: pointer;
}
.disabled {
    cursor: not-allowed;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-1 {
    flex: 1;
}

.shadow-1 {
    box-shadow: 0 1px 3px hsla(0, 0%, 0%, 12%), 0 1px 2px hsla(0, 0%, 0%, 24%);
}
.shadow-2 {
    box-shadow: 0 3px 6px hsla(0, 0%, 0%, 15%), 0 2px 4px hsla(0, 0%, 0%, 12%);
}
.shadow-3 {
    box-shadow: 0 10px 20px hsla(0, 0%, 0%, 15%), 0 3px 6px hsla(0, 0%, 0%, 10%);
}

a {
    color: rgb(82, 96, 109);
}

body {
    // max-width: 800px;
    margin: auto;
}

.title {
    letter-spacing: -0.5px;
}

.player {
    position: relative;

    .player-subtitles {
        transition: 0.3s all ease-in-out;
        position: absolute;
        bottom: 5px;
        left: 0px;
        right: 0px;
        background: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 5px;
        min-height: 30px;
        text-align: center;
        max-width: 460px;
        margin: auto;
        .subtitle-content {
            max-width: 400px;
            margin: auto;
        }
        &.desktop {
            max-width: 550px;
            bottom: 15px;
            .subtitle-content {
                font-size: 1.1rem;
                max-width: 500px;
            }
            &.fullscreen {
                max-width: 650px;
                .subtitle-content {
                    font-size: 1.3rem;
                    max-width: 600px;
                }
            }
        }
    }

    &:hover,
    &.isPaused {
        .player-subtitles {
            transform: translateY(-50px);
        }
    }
}

.subtitle-container {
    .player-subtitles {
        position: absolute;
        background: white;
        bottom: 135px;
        right: 0px;
        left: 0;
        padding: 5px;
        min-height: 30px;
        text-align: center;
        margin: auto;
        box-shadow: rgba(0, 0, 0, 0.167) 0px -2px 22px 6px;
        .subtitle-content {
            max-width: 400px;
            margin: auto;
        }
    }
}

.smallImage-hover:hover {
    img,
    source {
        transition: 0.2s all ease-in-out;
        filter: none;
        transform: scale(1.1);
    }
}

[data-reach-tooltip] {
    margin-left: 55px;
    margin-top: -50px;
}

.InlineBlockTrack,
.InlineTrack {
    .subtitle-sample {
        transition: 0.3s all ease-in-out;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.7);
        padding: 5px 5px 20px;
        color: white;
        text-align: center;
        font-size: 14px;
    }
    .playButton {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
        transition: 0.3s all ease-in-out;
        opacity: 0;
        font-size: 60px;
        position: absolute;
        left: 0;
        right: 0;
        width: 60px;
        top: 30px;
        margin: auto;
        color: white;
    }
    &.InlineTrack .playButton {
        top: 10px;
        font-size: 30px;
        right: auto;
        left: 45px;
    }
    &:hover .subtitle-sample {
        opacity: 0;
    }
    &:hover .playButton {
        opacity: 1;
        transform: scale(1.2);
    }
}

.white-space-nowrap {
    white-space: nowrap;
}

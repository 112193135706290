.IconButton--tooltip {
    display: none;
    .IconButton--tooltip--arrow {
        position: absolute;
        left: -10px;
        top: 7px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right-width: 10px;
    }
    .IconButton--tooltip--arrow-right {
        position: absolute;
        right: -10px;
        top: 7px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left-width: 10px;
    }
}
